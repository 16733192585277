import React, { useEffect, useState } from 'react'
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import { SearchProvider, WithSearch } from '@elastic/react-search-ui'
import { Link } from 'gatsby'
import { doc, getDoc } from 'firebase/firestore'
import Layout from '../containers/Layout'
import Seo from '../containers/SEO'
import { ArrowSmLeftIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import EmailSearchResults from '../common/search/EmailSearchResults'
import LineGraph from '../common/charts/LineGraph'
import { db } from '../utils/firebase'

const Entity = ({ id, location }) => {
  const [senderData, setSenderData] = useState(null)

  const connector = new AppSearchAPIConnector({
    searchKey: process.env.GATSBY_ELASTIC_SEARCH_KEY,
    engineName: process.env.GATSBY_ELASTIC_ENGINE_NAME,
    endpointBase: process.env.GATSBY_ELASTIC_URL,
    cacheResponses: false
  })

  useEffect(() => {
    const asyncInit = async () => {
      const docSnap = await getDoc(doc(db, 'senders', id))

        let volumeData = []
        if ([
          '5b117b7710695238826654d740d3a4b6',
          '95684ce2e2fb58caa41b7b0cf9250b68'
        ].includes(id)) {
          const response = await fetch(`/sender_volume/${id}.json`)
          volumeData = await response.json()
        } else {
          volumeData = (await getDoc(doc(db, 'stats', id))).data()
        }

      const volumeByMonth = calculateVolumeData(volumeData?.email_volume) || []

      setSenderData({
        ...docSnap.data(),
        volumeByMonth
      })
    }
    asyncInit()
  }, [])

  const calculateVolumeData = (data) => {
    if (!data) return
    const datesArray = data.map((vol) => Object.values(vol)[0])

    datesArray.sort((a, b) => new Date(a) - new Date(b))

    const volumeByMonthObject = {}
    // loop through dates, format the date and group the dates
    for (const date of datesArray) {
      const d = new Date(date)
      const firstOfMonth = new Date(
        d.getFullYear(),
        d.getMonth(),
        1,
        0,
        0,
        0,
        0
      )

      const formattedDate = format(firstOfMonth, 'MMM/yy')

      if (volumeByMonthObject[formattedDate]) {
        volumeByMonthObject[formattedDate]++
      } else {
        volumeByMonthObject[formattedDate] = 1
      }
    }

    // convert the object to an array for the charts
    return Object.entries(volumeByMonthObject).map(([name, value]) => ({
      name,
      value
    }))
  }

  const facetOptions = {
    sender_party_affiliation: {
      type: 'value'
    },
    sender_name: {
      type: 'value'
    },
    isdishonestsubject: {
      type: 'value'
    }
  }

  const searchQueryOptions = {
    result_fields: {
      text_plain: {
        snippet: {
          size: 600,
          fallback: true
        }
      },
      stripped_html: {
        snippet: {
          size: 600,
          fallback: true
        }
      },
      subject: {
        snippet: {
          size: 100,
          fallback: true
        }
      },
      from: { raw: {} },
      date: { raw: {} },
      id: { raw: {} },
      sender_name: { raw: {} },
      sender_office_sought: { raw: {} },
      sender_party_affiliation: { raw: {} }
    },
    facets: facetOptions
  }

  const configurationOptions = {
    debug: false, // debug info shows up in console
    apiConnector: connector,
    searchQuery: searchQueryOptions,
    alwaysSearchOnInitialLoad: true
  }

  return (
    <SearchProvider config={configurationOptions}>
      {/* TODO: Move this to a HoC, it's causing an error to be thrown */}
      <WithSearch mapContextToProps={(context) => context}>
        {(context) => {
          if (!context.filters.length && senderData) {
            context.setFilter('sender_name', senderData.name, 'any')
          }

          return (
            <Layout>
              <Seo title='View Sender' />
              <section className='py-4'>
                <div className='container !p-0'>
                  <Link
                    to='/senders/'
                    className='flex gap-4 text-xs no-underline label group'
                  >
                    <ArrowSmLeftIcon className='inline w-5 mb-1 text-gray-400 transition group-hover:-translate-x-1 group-hover:text-blue-400' />
                    Return to Results
                  </Link>
                </div>
              </section>
              <section className='p-0'>
                {senderData && senderData.final_website && (
                  <div className='container !p-4 bg-primary flex justify-between'>
                    <a
                      href={senderData.final_website}
                      target='_blank'
                      className='label'
                      rel='noreferrer'
                    >
                      {senderData.name} Website
                    </a>
                  </div>
                )}
                <div className='container !pb-0 grid grid-cols-1 lg:grid-cols-2 min-h-[350px]'>
                  <div>
                    {senderData && <h1>{senderData.name}</h1>}
                    <hr className='border-b-2 border-blue-400' />
                    <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 justify-between !pb-4  py-4'>
                      {senderData?.signup_date && (
                        <p className='label'>
                          Email Signup Date:
                          <br />
                          {format(
                            new Date(senderData.signup_date),
                            'd-MMM-yyyy'
                          )}
                        </p>
                      )}
                      {senderData?.first_email_date && (
                        <p className='!flex label'>
                          First Email:
                          <br />
                          {format(
                            new Date(senderData.first_email_date),
                            'd-MMM-yyyy'
                          )}
                        </p>
                      )}
                      {senderData?.last_email_date && (
                        <p className='!flex label'>
                          Most Recent Email:
                          <br />
                          {format(
                            new Date(senderData.last_email_date),
                            'd-MMM-yyyy'
                          )}
                        </p>
                      )}
                      {senderData?.final_website && (
                        <p className='flex label'>


                          <a href={senderData.final_website}>
                            Website
                          </a>
                        </p>
                      )}
                      {senderData?.office_sought && (
                        <p className='flex label'>
                          Office Sought:
                          <br />
                          {senderData?.office_sought}
                        </p>
                      )}
                      {senderData?.party_affiliation && (
                        <p className='flex label'>
                          Party Affiliation:
                          <br />
                          {senderData?.party_affiliation}
                        </p>
                      )}
                    </div>
                  </div>
                  {senderData && <LineGraph data={senderData.volumeByMonth} />}
                </div>
              </section>
              <EmailSearchResults results={context.results} />
            </Layout>
          )
        }}
      </WithSearch>
    </SearchProvider>
  )
}

export default Entity
