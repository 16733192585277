import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

const LineGraph = ({ data }) => {
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={800}
          height={300}
          data={data}
          margin={{
            top: 50,
            right: 0,
            left: 0,
            bottom: 27,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey='name' angle={-45} textAnchor="end" />
          <YAxis  />
          <Tooltip wrapperStyle={{ backgroundColor: "red" }} labelStyle={{ color: '#605757', textTransform: 'uppercase', fontWeight: '600', fontSize: '.875rem', letterSpacing: '.05rem', borderBottom: '2px solid #f0f0f0',textAlign: 'center' }} contentStyle={{ textTransform: 'uppercase', fontWeight: '600', fontSize: '.875rem', letterSpacing: '.05rem', }}  />
          {/* <Legend layout="horizontal" verticalAlign="top" align="center" height={50} iconType="square" /> */}
          <Line type="monotone" dataKey="value" stroke="#3B1858" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineGraph;
